<template>
  <v-container class="pt-3">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="4">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Información del comercio
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Esta informacion será mostrada a tus clientes al momento de
                realizar un pago.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <v-row>
              <!-- <v-col cols="12" md="8">
             <label for="code" class="text-sm">Codigo</label>
            <br />
            <input
              @change="enableEditing()"
              disabled
              v-model="myMerchant.code"
              type="text"
              id="code"
              placeholder="0000"
              :class="inputClasses"
            />
             </v-col> -->

              <v-col cols="12" md="4">
                <v-avatar @click="triggerFileInput" class="profile" size="90">
                  <v-img
                    :src="
                      myMerchant.logo ||
                      'https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc='
                    "
                  ></v-img>
                </v-avatar>
                <v-btn
                  class="ml-2 btn"
                  @click="triggerFileInput"
                  elevation="1"
                  icon
                >
                  <v-icon>mdi-image-plus</v-icon></v-btn
                >
                <v-file-input
                  ref="file"
                  @change="onFileChange"
                  style="display: none"
                  id="logoInput"
                  :rules="logoRules"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  label="Avatar"
                ></v-file-input>
              </v-col>

              <v-col cols="12" md="8">
                <validation-provider
                  v-slot="{ errors }"
                  name="companyName"
                  rules="required"
                >
                  <label for="companyName" class="text-sm"
                    >Nombre del comercio</label
                  >
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.companyName"
                    type="text"
                    id="companyName"
                    :error-messages="errors"
                    placeholder="Yoyo"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                  <span
                    class="waiting-for-review text-sm"
                    v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['companyName']
                    "
                    >{{ waitingForReviewMsg }}</span
                  >
                </validation-provider>
              </v-col>
            </v-row>
            <span
              class="p-0 waiting-for-review text-sm"
              v-if="
                pendingUpdateRequest &&
                pendingUpdateRequest.changes &&
                pendingUpdateRequest.changes['logo']
              "
              >{{ "Reviewing..." }}</span
            >
          </v-col>
        </v-row>

        <br />
        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="12" md="4">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Representante
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Informacion basica y de contacto del representante del comercio.
                El nombre del representante será presentado a sus clientes.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label for="name" class="text-sm">Nombre</label>
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.name"
                    type="text"
                    id="name"
                    :error-messages="errors"
                    placeholder="John Doe"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                  <span
                    class="waiting-for-review text-sm"
                    v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['name']
                    "
                    >{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="7">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <label for="email" class="text-sm">Email</label>
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.email"
                    type="email"
                    id="email"
                    :error-messages="errors"
                    placeholder="comercio@yoyo.do"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                  <span
                    class="waiting-for-review text-sm"
                    v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['email']
                    "
                    >{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="5">
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <label for="phone" class="text-sm">Telefono</label>
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.phone"
                    type="tel"
                    id="phone"
                    :error-messages="errors"
                    placeholder="18299462555"
                    :class="inputClasses"
                    required
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                  <span
                    class="waiting-for-review text-sm"
                    v-if="
                      pendingUpdateRequest &&
                      pendingUpdateRequest.changes &&
                      pendingUpdateRequest.changes['phone']
                    "
                    >{{ waitingForReviewMsg }}
                  </span>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" name="documentType">
                  <label for="documentType" class="text-sm"
                    >Tipo de Documento</label
                  >
                  <br />
                  <select
                    @change="enableEditing()"
                    :class="inputClasses"
                    v-model="myMerchant.document.type"
                    :error-messages="errors"
                    name="documentType"
                    id="documentType"
                  >
                    <option
                      v-for="i in documentTypes"
                      :key="i.value"
                      :value="i.value"
                    >
                      {{ i.text }}
                    </option>
                  </select>

                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="documentNumber"
                  rules="required"
                >
                  <label for="documentNumber" class="text-sm">Documento</label>
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.document.number"
                    type="tel"
                    id="documentNumber"
                    :error-messages="errors"
                    placeholder="###-#######-#"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>

                <span
                  class="waiting-for-review text-sm"
                  v-if="
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['document']
                  "
                  >{{ waitingForReviewMsg }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <br />
        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="12" md="4">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Información Bancaria
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Esta informacion es privada, no es compartida con nadie. Tus
                fondos en Yoyo seran depositados en esta cuenta una vez haya
                sido validada.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="12">
                <v-card outline>
                  <v-card-title
                    >{{ myMerchant.bankAccounts[0].holder }} -
                    {{ myMerchant.bankAccounts[0].number }}
                  </v-card-title>
                  <v-card-subtitle
                    >{{
                      banks.find(
                        (x) => x.value === myMerchant.bankAccounts[0].bank
                      )
                        ? banks.find(
                            (x) => x.value === myMerchant.bankAccounts[0].bank
                          ).text
                        : myMerchant.bankAccounts[0].bank
                    }}

                    {{
                      bankAccountTypes.find(
                        (x) => x.value === myMerchant.bankAccounts[0].type
                      ) &&
                      bankAccountTypes.find(
                        (x) => x.value === myMerchant.bankAccounts[0].type
                      ).value !== ""
                        ? "| " +
                          bankAccountTypes.find(
                            (x) => x.value === myMerchant.bankAccounts[0].type
                          ).text
                        : ""
                    }}</v-card-subtitle
                  >

                  <v-divider class="mx-4"></v-divider>
                  <v-card-actions class="justify-end">
                    <span
                      class="p-1 waiting-for-review text-center text-sm"
                      v-if="
                        pendingUpdateRequest &&
                        pendingUpdateRequest.changes &&
                        pendingUpdateRequest.changes['bankAccounts']
                      "
                      >{{ waitingForReviewMsg }}
                    </span>
                    <v-btn
                      v-if="toggleBankAccountInputs"
                      @click="toggleBankAccountInputs = false"
                      color="red darken-1"
                      text
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      disabled
                      v-if="!toggleBankAccountInputs"
                      @click="toggleBankAccountInputs = true"
                      color="blue darken-1"
                      text
                    >
                      Editar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="7">
                <validation-provider
                  v-slot="{ errors }"
                  name="Banco"
                  rules="required"
                >
                  <label for="Banco" class="text-sm">Banco</label>
                  <br />
                  <select
                    @change="enableEditing()"
                    :class="inputClasses"
                    v-model="myMerchant.bankAccounts[0].bank"
                    :error-messages="errors"
                    name="Banco"
                  >
                    <option v-for="i in banks" :key="i.value" :value="i.value">
                      {{ i.text }}
                    </option>
                  </select>

                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="5">
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo de Cuenta"
                  rules="required"
                >
                  <label for="Tipo de Cuenta" class="text-sm"
                    >Tipo de Cuenta</label
                  >
                  <br />
                  <select
                    @change="enableEditing()"
                    :class="inputClasses"
                    v-model="myMerchant.bankAccounts[0].type"
                    :error-messages="errors"
                    name="Tipo de Cuenta"
                  >
                    <option
                      v-for="i in bankAccountTypes"
                      :key="i.value"
                      :value="i.value"
                    >
                      {{ i.text }}
                    </option>
                  </select>
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Numero De Cuenta"
                  rules="required"
                >
                  <label for="Numero De Cuenta" class="text-sm"
                    >Numero De Cuenta</label
                  >
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.bankAccounts[0].number"
                    type="tel"
                    id="Numero De Cuenta"
                    :error-messages="errors"
                    placeholder="0000000000"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                  <span style="color: orange">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col v-if="toggleBankAccountInputs" cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Titular de Cuenta"
                  rules="required"
                >
                  <label for="Titular de Cuenta" class="text-sm"
                    >Titular de Cuenta</label
                  >
                  <br />
                  <input
                    @change="enableEditing()"
                    v-model="myMerchant.bankAccounts[0].holder"
                    type="tel"
                    id="Titular de Cuenta"
                    :error-messages="errors"
                    placeholder="John Doe"
                    :class="inputClasses"
                  />
                  <span style="color: red">{{ errors[0] }}</span>
                </validation-provider>

                <span
                  class="waiting-for-review text-sm"
                  v-if="
                    pendingUpdateRequest &&
                    pendingUpdateRequest.changes &&
                    pendingUpdateRequest.changes['bankAccounts']
                  "
                  >{{ waitingForReviewMsg }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col cols="12" md="12">
            <v-btn
              :disabled="invalid || !enabled"
              color="success"
              class="mr-4 mt-4"
              @click="requestUpdate"
            >
              Actualizar Informacion
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <alert ref="alert" />
  </v-container>
</template>
<script>
import { renewSession } from "@/utils";
import Alert from "@/components/Alert.vue";
import dot from "dot-object";
import Vue from "vue";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default Vue.extend({
  name: "Settings",
  data: () => ({
    enabled: false,
    toggleBankAccountInputs: false,
    pendingUpdateRequest: {},
    inputClasses:
      "shadow appearance-none border rounded w-full py-3 px-2 text-gray-700 mt-3 leading-tight focus:outline-none focus:shadow-outline",
    logoRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 30) || "Name must be less than 10 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    error: "",
    documentTypes: [
      { value: "country_issued", text: "Cedula" },
      { value: "passport", text: "Pasaporte" },
      { value: "business", text: "RNC" },
    ],
    bankAccountTypes: [
      { value: "checking", text: "Cuenta Corriente" },
      { value: "savings", text: "Cuenta De Ahorros" },
      { value: "", text: "" }, //unset type
    ],
    banks: [],

    waitingForReviewMsg:
      "Este cambio esta siendo revisado por la administracion de Yoyo.",
    myMerchant: {
      code: "",
      name: "",
      phone: "",
      email: "",
      companyName: "",
      document: {
        type: "country_issued" | "passport" | "business",
        number: "",
      },
      bankAccounts: [{ bank: "", number: "", type: "", holder: "" }],
      logo: "",
      qr: "",
      username: "",
    },
  }),
  props: {
    merchant: {},
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Alert,
  },
  watch: {
    merchant() {
      if (!this.pendingUpdateRequest)

      // console.log("@merchant", this.merchant);
        if (!!this.merchant._id)
          this.myMerchant = {
            ...this.merchant,
            document: {
              type: this.merchant.document.type,
              number: this.merchant.document.number,
            },
            bankAccounts: [
              {
                type: this.merchant.bankAccounts[0].type || "",
                number: this.merchant.bankAccounts[0].number || "",
                holder: this.merchant.bankAccounts[0].holder || "",
                bank: this.merchant.bankAccounts[0].bank || "",
              },
            ],
            logo: this.merchant.logo,
          };
    },
    pendingUpdateRequest() {
      // console.log(
      //   "@pendingUpdateRequest",
      //   JSON.stringify(this.merchant),
      //   this.pendingUpdateRequest
      // );

      this.myMerchant = this.pendingUpdateRequest
        ? {
            ...this.pendingUpdateRequest.preview,
            document: {
              type: this.pendingUpdateRequest.preview.document.type,
              number: this.pendingUpdateRequest.preview.document.number,
            },
            bankAccounts: [
              {
                type:
                  this.pendingUpdateRequest.preview.bankAccounts[0].type || "",
                number:
                  this.pendingUpdateRequest.preview.bankAccounts[0].number ||
                  "",
                holder:
                  this.pendingUpdateRequest.preview.bankAccounts[0].holder ||
                  "",
                bank:
                  this.pendingUpdateRequest.preview.bankAccounts[0].bank || "",
              },
            ],
            logo: this.pendingUpdateRequest.preview.logo,
          }
        : {
            ...(!!this.merchant._id
              ? {
                  ...this.merchant,
                  document: {
                    type: this.merchant.document.type,
                    number: this.merchant.document.number,
                  },
                  bankAccounts: [
                    {
                      type:
                        (this.merchant.bankAccounts &&
                          this.merchant.bankAccounts[0] &&
                          this.merchant.bankAccounts[0].type) ||
                        "",
                      number: this.merchant.bankAccounts[0].number || "",
                      holder: this.merchant.bankAccounts[0].holder || "",
                      bank: this.merchant.bankAccounts[0].bank || "",
                    },
                  ],
                  logo: this.merchant.logo,
                }
              : {}),
          };

      //console.log(
      //  "@pendingUpdateRequest",
      //  this.merchant,
      //  this.pendingUpdateRequest
     // );
    },
  },
  async mounted() {
    this.getBanks();
    this.getPendingUpdate();
  },
  methods: {
    onFileChange() {
      const imagefile = document.querySelector("#logoInput");
      const [file] = imagefile.files;
      if (file) {
        this.myMerchant.logo = URL.createObjectURL(file);
        this.enabled = true;
      }
    },
    async getBanks() {
      const resp = await fetch(`${process.env.VUE_APP_API_URL}/misc/banks`, {
        headers: {
          "Content-Type": "application/json",
          "x-yoyo-key": process.env.VUE_APP_API_KEY,
        },
      });

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.banks = data.map((x) => ({ value: x.code, text: x.bankName }));
        renewSession(resp);
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    async getPendingUpdate() {
      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/merchants/pending-update`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-yoyo-key": localStorage.getItem("session"),
          },
        }
      );

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.pendingUpdateRequest = data;
        renewSession(resp);
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    triggerFileInput() {
      this.$refs.file.$refs.input.click();
    },
    enableEditing() {
      this.enabled = true;
    },
    async requestUpdate() {
      // console.log(this.$refs.form);
      if (this.$refs.form.validate()) {
        const agree = await this.$refs.alert.showAlert({
          title: "Actualizar",
          text: "Verificaste la informacion?",
          buttons: [
            { text: "No", color: "red", role: "cancel" },
            { text: "Si" },
          ],
        });

        if (!agree) return;

        var formData = new FormData();
        var imagefile = document.querySelector("#logoInput");
        const [file] = imagefile.files;
        if (file) formData.append("logo", file);

        const merchantInfo = {
          name: this.myMerchant.name,
          phone: this.myMerchant.phone,
          email: this.myMerchant.email,
          companyName: this.myMerchant.companyName,
          document: {
            type: this.myMerchant.document.type,
            number: this.myMerchant.document.number,
          },
          //   logo: "",
        };

        const bankAccount = {
          bankAccount: {
            type: this.myMerchant.bankAccounts[0].type,
            number: this.myMerchant.bankAccounts[0].number,
            holder: this.myMerchant.bankAccounts[0].holder,
            bank: this.myMerchant.bankAccounts[0].bank,
          },
        };
        const dotifiedBody = {
          ...dot.dot(bankAccount),
          ...dot.dot(merchantInfo),
        };
        for (const key of Object.keys(dotifiedBody)) {
          formData.append(key, dotifiedBody[key]);
        }

        const resp = await fetch(
          `${process.env.VUE_APP_API_URL}/merchants/request-update`,
          {
            method: "POST",
            headers: {
              "x-yoyo-key": localStorage.getItem("session"),
            },
            body: formData,
          }
        );

        const { ok, data, errors, errorMessage } = await resp.json();
        if (ok) {
          this.myMerchant = data.preview;
          this.pendingUpdateRequest = data;
          this.enabled = !this.enabled;
          // this.$emit("update:merchant", data);

          this.toggleBankAccountInputs = false;
          this.$refs.alert.showAlert({
            title: "Aviso!",
            text: "Tus actualizaciones estaran siendo revisadas por un representante de Yoyo, una vez hecho, tus cambios serán aplicados y te notificaremos.",
            buttons: [{ text: "Ok" }],
          });
          renewSession(resp);
        }
        if (!ok) {
          if (errorMessage) this.error = errorMessage;
          else if (errors) {
            this.error = errors[0].msg;
          } else {
            this.error = resp.statusText;
          }
        }
      }
    },
  },
});
</script>
<style scoped>
.container {
  width: 70% !important;
}

.waiting-for-review {
  color: orange;
}

.btn {
  background-color: white !important;
  border: #f78f6e 2px solid;
  position: relative;
  right: 35px;
  top: 30px;
}
</style>
